import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import anime from 'animejs';
import Link from 'gatsby-plugin-transition-link';
import ImageParser from 'components/imageParser';

//COMPONENTS
import Layout from 'components/layout';
import { DirectionArrow } from 'components/svg';
import { LinkHoverColor, getVar } from 'utils/common';
import { InViewportClass } from 'utils/inviewport';
import { MediaCheck } from 'utils/mediacheck';
// import { ImageLoadClass } from 'utils/loader';

//SOURCES
import 'stylesheet/projects.scss';
import { GlobalContext } from '../context/GlobalContext';

const Projects = ({ location, data }) => {
  const context = useContext(GlobalContext);
  const inviewQuery = [];

  const toggleLinks = (selectedLinks) => {
    if (typeof document !== 'undefined') {
      const projectLinksQuery = document.querySelectorAll(
        '.projectsDescription .container > a'
      );
      projectLinksQuery.forEach((link, index) => {
        if (typeof index === undefined) {
          index = -1;
        }
        if (index !== selectedLinks) {
          link.classList.remove('active');
        } else {
          link.classList.add('active');
        }
      });
    }
  };

  let currentIndex = 0;

  const postLoadProject = () => {
    context.enableScrollToggle = false;
    if (typeof document !== 'undefined') {
      const mainlogoSelector = document.querySelector('#mainlogo');
      if (mainlogoSelector) mainlogoSelector.style.height = null;
      const projectThumbQuery = document.querySelectorAll('.projectthumbnail');
      projectThumbQuery.forEach((project, index) => {
        inviewQuery.push(
          new InViewportClass({
            target: project,
            visibility: 0.5,
            customeventResponsiveCheck: () => {
              return MediaCheck.touch();
            },
            customevent: 'OverlayScroll',
            enter: () => {
              toggleLinks(index);
              currentIndex = index;
            },
            exit: () => {},
          })
        );
      });
    }
  };
  useEffect(() => {
    toggleLinks(0);
    return () => {
      // imageLoader.kill();
      inviewQuery.forEach((inView, index) => {
        inviewQuery[index].kill();
      });
    };
  }, []);
  const projectanimationExit = {
    length: 1,
    zIndex: 1,
    trigger: ({ exit, node }) => {
      if (typeof window !== 'undefined') {
        document.body.classList.add('transitioning');
        context.toggleMobileMenu(false);
        inviewQuery.forEach((inView, index) => {
          inviewQuery[index].kill();
        });
        toggleLinks(currentIndex);
      }

      const scrollTop =
        (document.scrollingElement && document.scrollingElement.scrollTop) ||
        document.body.scrollTop ||
        window.pageYOffset;

      node.style.overflowY = 'hidden';
      node.style.height = `100vh`;
      node.scrollTop = scrollTop;
    },
  };
  const projectanimationEntry = {
    delay: 0,
    zIndex: 2,
    trigger: ({ entry, node }) => {
      node.querySelector('main').style.boxShadow =
        '0px 0px 20px rgba(0, 0, 0, 0.25)';
      anime({
        targets: node.querySelector('main'),
        left: {
          value: [getVar.windowW(), 0],
          duration: 750,
          delay: 0,
          easing: 'easeInOutCubic',
        },
      });
      anime({
        targets: document.querySelector('.viewinfo-project'),
        translateX: {
          value: [getVar.windowW(), 0],
          duration: 750,
          delay: 0,
          easing: 'easeInOutCubic',
        },
      });
      anime({
        targets: document.querySelector('.mobilebackbutton'),
        translateX: {
          value: [getVar.windowW(), 0],
          duration: 750,
          delay: 0,
          easing: 'easeInOutCubic',
        },
      });
    },
  };
  return (
    <GlobalContext.Consumer>
      {(context) => (
        <Layout
          titleText={'Projects'}
          mainID={'Projects'}
          location={location}
          postLoad={postLoadProject}
        >
          <div>
            <div>
              <div className='projectsDescription'>
                <div className='container'>
                  {data.projects.edges.map(({ node }, index) => (
                    <Link
                      to={`${node.fields.slug}`}
                      exit={projectanimationExit}
                      entry={projectanimationEntry}
                      onMouseEnter={LinkHoverColor}
                      onMouseLeave={LinkHoverColor}
                      key={index}
                    >
                      <span>{node.frontmatter.title}</span>
                      <span>
                        {node.frontmatter.category} — {node.frontmatter.date}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className='archivesContainer'>
              <Link
                className='linktoArchives'
                to='/archives'
                exit={{
                  length: 1,
                  zIndex: 2,
                  trigger: ({ exit, node }) => {
                    if (typeof window !== 'undefined') {
                      document.body.classList.add('transitioning');
                      context.toggleMobileMenu(false);

                      inviewQuery.forEach((inView, index) => {
                        inviewQuery[index].kill();
                      });
                      toggleLinks(currentIndex);

                      if (MediaCheck.touch()) {
                        const scrollTop =
                          (document.scrollingElement &&
                            document.scrollingElement.scrollTop) ||
                          document.body.scrollTop ||
                          window.pageYOffset;

                        node.style.overflowY = 'hidden';
                        node.style.height = '100vh';
                        node.scrollTop = scrollTop;
                      }

                      anime({
                        targets: node.querySelector('main'),
                        left: {
                          value: [0, -getVar.windowW()],
                          duration: 750,
                          delay: 0,
                          easing: 'easeInOutCubic',
                        },
                      });
                      anime({
                        targets: node.querySelector('.linktoArchives'),
                        translateX: {
                          value: [0, -getVar.windowW()],
                          duration: 750,
                          delay: 0,
                          easing: 'easeInOutCubic',
                        },
                      });

                      node.querySelector('main').style.boxShadow =
                        '0px 0px 20px rgba(0, 0, 0, 0.25)';
                    }
                  },
                }}
                entry={{
                  delay: 0,
                  zIndex: 1,
                }}
                onMouseEnter={LinkHoverColor}
                onMouseLeave={LinkHoverColor}
              >
                <DirectionArrow />{' '}
                <span>{data.setting.frontmatter.archive_label}</span>
              </Link>
            </div>
          </div>
          <div>
            {data.projects.edges.map(({ node }, index) => {
              return (
                <div className='fitheight' key={index}>
                  <Link
                    className=' projectthumbnail'
                    to={`${node.fields.slug}`}
                    exit={projectanimationExit}
                    entry={projectanimationEntry}
                    onMouseEnter={LinkHoverColor}
                    onMouseLeave={LinkHoverColor}
                  >
                    <div>
                      <ImageParser
                        className={`${node.frontmatter.coverimage.position_x}${node.frontmatter.coverimage.position_y}`}
                        imgStyle={{
                          objectFit: 'cover',
                          objectPosition: `${node.frontmatter.coverimage.position_x} ${node.frontmatter.coverimage.position_y}`,
                          width: '100%',
                          height: '100%',
                        }}
                        style={{
                          background: '#DDDDDD',
                          width: '100%',
                          height: '100%',
                        }}
                        loading='lazy'
                        relativepath={
                          node.frontmatter.coverimage.file.publicURL
                        }
                        childImageSharp={
                          node.frontmatter.coverimage.file.childImageSharp
                        }
                        alt='Brownfox Project'
                      />
                    </div>
                    <span className='mobileinfo'>
                      <span>{node.frontmatter.title}</span>
                      <span>
                        {node.frontmatter.category} — {node.frontmatter.date}
                      </span>
                    </span>
                  </Link>
                </div>
              );
            })}
          </div>
        </Layout>
      )}
    </GlobalContext.Consumer>
  );
};

export const query = graphql`
  query {
    setting: markdownRemark(
      frontmatter: {
        issetting: { eq: true }
        contenttype: { eq: "general_setting" }
      }
    ) {
      html
      frontmatter {
        archive_label
      }
    }
    projects: allMarkdownRemark(
      filter: {
        frontmatter: {
          issetting: { eq: false }
          contenttype: { eq: "projects" }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY")
            category
            slug
            coverimage {
              file {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                  )
                }
              }
              position_x
              position_y
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;

export default Projects;
